import React from "react";

export default function Footer() {
    return(
        <div className="Footer">
            <h3>Copyright &copy; 2022 Kevin Wei</h3>

            <p>
                
            </p>

        </div>
    )
}